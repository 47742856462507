/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//GRIDSTACK -- MODULOS
@import "~gridstack/dist/gridstack.min.css";
@import "~gridstack/dist/gridstack-extra.min.css";

//SWIPERSJS -- SLIDERS
@import '~swiper/scss';
@import "~swiper/scss/effect-fade";
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@import '~lightgallery/scss/lightgallery';
@import "~@angular/cdk/overlay-prebuilt.css";
@import 'simple-keyboard/build/css/index.css';

:root {
  /*--color-text: navy;*/
  --color-text: #000;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 35s;
  --scroll-start: 0;
  --scroll-end: calc(-100%);
  /*--scroll-end: calc(-100% - var(--gap));*/
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: papayawhip;
    --color-bg: navy;
    --color-bg-accent: #2626a0;
  }
}

.simple-keyboard .hg-button {
  height: 80px !important;
}

.ks-modal-gallery-backdrop {
  background: #000 !important;;
  opacity: 0.85 !important;;
}
.ks-modal-gallery-panel {
  z-index: 90000 !important;
}

.events-news{
  --width: 80%;
  --height: 60%;
  --border-radius: 15px;
}

.email-input-modal{
  --width: 90%;
  --height: 42%;
  --border-radius: 15px;
}

ion-modal{
  --width: 100%;
  --height: 100%;
}

ion-content{
  --background: #fff !important;
}

/*
.divSubToolBar{
  height: 9%;
}
*/

.md,
.ios,
.wp {
  .custom-alert-danger {
    > div {
      background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
      text-align: center !important;
      zoom: 1;
    }

    .alert-head{
      text-align: center !important;
    }
  }
}

.md,
.ios,
.wp {
  .custom-modal-img {
    > div {
      background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
      text-align: center !important;
      // zoom: 2;
    }

    .alert-head{
      text-align: center !important;
    }

    .alert-wrapper{
      --width: 90% !important;
      --min-width: 90% !important;
      --max-width: 90% !important;
      height: auto;
    }
    .alert-message{
      max-height: 100% !important;
    }

  }
}

body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.weatherWidget{
  display: none;
}

.ion-page{
  overflow: visible !important;
  --overflow: visible !important;
}

.app-infobar{
  z-index: 99;
}

.gridStackContent{
  --overflow: auto;
}

@media (min-height: 1200px) {
  .md,
  .ios,
  .wp {
    .custom-alert-danger {
      > div {
        background: #fff !important; // if you don't use .md, .ios, .mp initially, you'd have to use !important here.
        text-align: center !important;
        zoom: 2;
      }

      .alert-head{
        text-align: center !important;
      }
    }
  }

}

@media screen and (width: 1080px) and (height: 1920px) {
  /*
  .divSubToolBar{
    height: 6%;
  }
  */

  .weatherWidget{
    display: block;
  }

  /*
  .gridStackContent{
    --overflow: hidden !important;
  }
  */
}

@media screen and (width: 768px) and (height: 1376px) {
  /*
  .divSubToolBar{
    height: 6%;
  }
  */

  .weatherWidget{
    display: block;
  }

  /*
  .gridStackContent{
    --overflow: hidden !important;
  }
  */

  .banner-monitor-minimize{
    height: 110px !important;
  }

  .btnWeatherQR {
    height: 72px;
    width: 72px;
    border-radius: 100px;
  }
}


@media (min-height: 1230px) {
  .weatherWidget{
    display: block;
  }
}

/*
@media (max-width: 500px) {
  .divSubToolBar{
    height: 7%;
  }
}
*/

@media (max-width: 699px) {
  .weatherWidget{
    display: none;
  }
}